<template>
  <v-dialog
    :value="mostrar"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Selecionar coordenada
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            color="primary"
            @click="confirmar"
          >
            Confirmar
          </v-btn>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div id="wrapper">
        <GmapMap
          id="map"
          :options="{
            streetViewControl: false,
            fullscreenControl: false
          }"
          :center="center"
          :zoom="14"
          @click="clickMapa"
        >
          <GmapMarker
            v-if="coordenada"
            :position="coordenada"
          />
        </GmapMap>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
#wrapper
  position: relative

#map
  width: 100%
  height: 500px
  display: absolute

#over_map
  position: absolute
  top: 0,
  left: 0,
  width: 100%
  z-index: 99
  text-align: right
</style>

<script>
  import { gmapApi } from 'vue2-google-maps'

  export default {

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        center: {
          lat: -29.448761,
          lng: -52.005460,
        },
        coordenada: null,
      }
    },

    computed: {
      google: gmapApi,
    },

    methods: {

      clickMapa (e) {
        this.coordenada = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        }
      },

      confirmar () {
        if (!this.coordenada) {
          return this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Selecione um ponto no mapa' })
        }

        this.$emit('coordenada-selecionada', this.coordenada)
      },

    },

  }
</script>
